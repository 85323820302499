
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import TransactionTable from "@/components/transaction/TransactionTable.vue";
import OrderTable from "@/components/transaction/OrderTable.vue";
import GraphContainer from "@/views/reports/GraphContainer.vue";
import PintTempsGraph from "@/components/graphs/PintTempsGraph.vue";
import KioskSessionErrorsTable from "@/components/kioskSession/KioskSessionErrorsTable.vue";
import {
  KioskSessionModel,
  OperationMode,
  OperationModeConstantModel,
} from "@/api/generated";
import api from "@/api";
import KioskSessionCard from "@/components/kioskSession/KioskSessionCard.vue";

@Component({
  components: {
    TransactionTable,
    KioskSessionCard,
    GraphContainer,
    OrderTable,
    PintTempsGraph,
    KioskSessionErrorsTable,
  },
})
export default class KioskSessionProfile extends Vue {
  @Prop(String) private kioskSessionId!: string;
  private model: KioskSessionModel = {
    sessionId: "",
    kioskId: "",
    kioskSessionId: "",
    startedUtc: "",
    closedUtc: "",
    operationMode: OperationMode.NUMBER_0,
    kioskKioskCode: "",
    createdUtc: "",
  };
  private tableRefreshKey = false;
  private startKioskSessionDialog = false;
  private endKioskSessionDialog = false;
  private selectedKioskId = "";
  private operationModes: Array<OperationModeConstantModel> = [];

  private async created() {
    this.getKioskSessionById();
    this.getOperationModes();
  }
  private async getKioskSessionById() {
    const response =
      await api.KioskSessionService.apiKiosksessionKioskSessionIdGet(
        this.kioskSessionId
      );
    this.model = response.data;
  }
  private async getOperationModes() {
    const response = await api.ConstantService.operationmodesGet();
    this.operationModes = response.data;
  }
  private async endKioskSession(id: string) {
    await api.KioskSessionService.apiKiosksessionCloseKioskSessionIdPost(id);
    this.getKioskSessionById();
  }
  private async startKioskSession(id: string) {
    await api.KioskSessionService.apiKiosksessionStartKioskSessionIdPost(id);
    this.getKioskSessionById();
  }

  private buttonClicked(dialogName: string, id: string) {
    this.selectedKioskId = id;
    if (dialogName == "startKioskSessionDialog") {
      this.startKioskSessionDialog = true;
    } else if (dialogName == "endKioskSessionDialog") {
      this.endKioskSessionDialog = true;
    }
  }

  private get roundedTotalRevenue() {
    return this.model.totalRevenue?.toFixed(2);
  }
}
