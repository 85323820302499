
import { Vue, Component, Prop } from "vue-property-decorator";
import VueApexCharts from "vue-apexcharts";
import { ApexOptions } from "apexcharts";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import { TempGraphModel } from "@/api/generated";
import api from "@/api";
import moment from "moment";

Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
const snackbarModule = getModule(SnackbarModule);

@Component
export default class PintTempsGraph extends Vue {
  @Prop(String) private readonly kioskSessionId!: string;
  private leftPintTemps: Array<TempGraphModel> = [];
  private rightPintTemps: Array<TempGraphModel> = [];
  private leftKegTemps: Array<TempGraphModel> = [];
  private rightKegTemps: Array<TempGraphModel> = [];
  private coolerTemps: Array<TempGraphModel> = [];

  private async created() {
    await this.getLeftPintTemps();
    await this.getRightPintTemps();
    await this.getLeftKegTemps();
    await this.getRightKegTemps();
    await this.getCoolerTemps();
  }

  private async getLeftPintTemps() {
    const response =
      await api.ReportingApi.apiReportingLeftPintTempKioskSessionIdGet(
        this.kioskSessionId
      );
    this.leftPintTemps = response.data;
  }

  private async getRightPintTemps() {
    const response =
      await api.ReportingApi.apiReportingRightPintTempKioskSessionIdGet(
        this.kioskSessionId
      );
    this.rightPintTemps = response.data;
  }

  private async getLeftKegTemps() {
    const response =
      await api.ReportingApi.apiReportingLeftKegTempKioskSessionIdGet(
        this.kioskSessionId
      );
    this.leftKegTemps = response.data;
  }

  private async getRightKegTemps() {
    const response =
      await api.ReportingApi.apiReportingRightKegTempKioskSessionIdGet(
        this.kioskSessionId
      );
    this.rightKegTemps = response.data;
  }

  private async getCoolerTemps() {
    const response =
      await api.ReportingApi.apiReportingCoolerTempKioskSessionIdGet(
        this.kioskSessionId
      );
    this.coolerTemps = response.data;
  }

  private get series() {
    // keg and pint temps switched around until Kyle sorts on his end
    return [
      {
        name: "Left Pint",
        data: this.leftKegTemps.map((x) => x.temperature),
      },
      {
        name: "Right Pint",
        data: this.rightKegTemps.map((x) => x.temperature),
      },
      {
        name: "Left Keg",
        data: this.leftPintTemps.map((x) => x.temperature),
      },
      {
        name: "Right Keg",
        data: this.rightPintTemps.map((x) => x.temperature),
      },
      {
        name: "Cooler",
        data: this.coolerTemps.map((x) => x.temperature),
      },
    ];
  }

  private get options() {
    return {
      chart: {
        type: "line",
      },
      xaxis: {
        categories: this.leftPintTemps.map((x) =>
          moment.utc(x.created).local().format("HH:mm")
        ),
        show: false,
        labels: {
          show: false,
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      title: {
        text: "Temperatures",
        align: "left",
        margin: 30,
        offsetX: 0,
        offsetY: 0,
        floating: false,
        style: {
          fontSize: "20px",
          fontWeight: 500,
          fontFamily: "Roboto",
          color: "#263238",
        },
      },
    };
  }
}
